



















import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api"
import { Editor, EditorContent } from "tiptap"
import Loading from "@/components/Loading.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { Meeting } from "@/models/Meeting"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import { meetingOrder } from "@/utilities/Order"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface Option {
  id: string
  value: string
  spId: string
}

interface State {
  selectedItem: string
  memo: object
  isLoadingMemo: boolean
  editor: typeof Editor | null
}

export default defineComponent({
  props: {
    value: {
      type: String,
      default: "",
    },
    meeting: {
      type: Meeting,
      required: true,
    },
    parentId: {
      type: String,
      default: "",
    },
  },
  components: {
    Loading,
    EditorContent,
    RichEditor,
  },
  setup(props) {
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const {
      state: MeetingState,
      getMemoValue,
    } = meetingContainer.useContainer()
    const state = reactive<State>({
      selectedItem: "",
      memo: {},
      isLoadingMemo: true,
      editor: null,
    })

    const items = computed(
      () =>
        MeetingState.meetings.value
          ?.find(v => v.id === props.parentId)
          ?.meetings.sort(meetingOrder)
          .filter(
            m =>
              m.startTime.getDifference(props.meeting.startTime, "minutes") < 0
          )
          .map(
            m =>
              ({
                id: m.id,
                value: `${m.startTime.toDateJpString()} (${m.startTime.toJpDayOfWeek()}) ${
                  m.subject
                }`,
                spId: m.sharepointItemId,
              } as Option)
          ) ?? []
    )

    watch(
      () => props.meeting,
      () => {
        state.selectedItem = ""
      }
    )

    watch(
      () => state.selectedItem,
      async (item: string) => {
        state.isLoadingMemo = true
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        const target = items.value.find(i => i.id === item)
        if (target) {
          const currentMemo = await getMemoValue(
            project.siteId,
            context.entityId,
            target.spId
          )
          state.memo = currentMemo ? JSON.parse(currentMemo).doc : ""
        }
        state.isLoadingMemo = false
      }
    )

    return {
      items,
      state,
    }
  },
})
